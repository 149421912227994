import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import oopline from '../images/oopsline.png';
import girl404 from '../images/404girl.jpg';
import { useLatestBlog } from '../hooks/getLatestBlog';
import '../styles/app.scss';

const NotFoundPage = () => {
    const dataBlog = useLatestBlog();
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'Jun',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    return (
        <>
            <HeaderMain />
            <section className="page-section smallestwdt nopaddbottsec">
                <div className="container">
                    <div className="oopstxt">
                        <h2 className="bluetxt">404 oops!</h2>
                        <p className="greentxt">Something went wrong.</p>
                    </div>
                    <div className="oopsimg">
                        <img src={girl404} alt="404 girl" />
                    </div>
                </div>
                <img className="oopsline" src={oopline} alt="oopsline" />
            </section>

            <section className="page-section smallwdt" id="oopseventswrap">
                <p>
                    The page you’re trying to access doesn’t seem to exist. Here
                    are some helpful resources instead:
                </p>
                <div className="container container_404">
                    {dataBlog.allWordpressPost.nodes.map(article => (
                        <Link
                            to={`/${article.slug}`}
                            className="homeeventbox"
                            key={article.id}
                        >
                            <h5
                                className="bluetxt"
                                dangerouslySetInnerHTML={{
                                    __html: article.title,
                                }}
                            />
                            <p>
                                {months[new Date(article.date).getMonth()]}
                                {` `}
                                {new Date(article.date).getDay()}
                                {` , `}
                                {new Date(article.date).getFullYear()}
                            </p>
                        </Link>
                    ))}
                </div>
            </section>
            <Footer />
        </>
    );
};

export default NotFoundPage;
